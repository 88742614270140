import React from 'react';
import './App.css';

export default function App(props) {
  const {children} = props;
  return (
      <div className="App">
        {children}
      </div>
  );
}
