import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import App from './App';
import {Row} from "react-material-responsive-grid";
// import {MenuContextProvider} from "./components/Globals/MenuContext";


const Landing = React.lazy(() => import('./components/Landing/Landing'));
const Login = React.lazy(() => import('./components/Login/Login'));
// const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const Blog = React.lazy(() => import('./components/Landing/Blog'));


const Loading = () => {
    return (
        <Row className='uk-width-1-1'
             style={{
                 margin: 0,
                 padding: 0,
                 width: '100vw',
                 height: '100vh',
                 justifyContent: 'center',
                 alignItems: 'center',
                 backgroundColor: 'rgba(255,255,255,0.8)'
             }}>
            <img style={{width: '350px', objectFit: 'contain'}} src="./images/Logo1.jpg" alt=""/>
        </Row>)

}

const AppRoutes = () => (<Suspense fallback={<Loading/>}>
    <App>
        <Switch>
            <Route exact path='/' component={Landing}/>
            <Route exact path='/login' component={Login}/>
            <Route exact path='/blog' component={Blog}/>
            {/*<MenuContextProvider>*/}
            {/*    <Route exact path='/dashboard' component={Dashboard}/>*/}
            {/*</MenuContextProvider>*/}
        </Switch>
    </App>
</Suspense>);

export default AppRoutes;
